import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizationService } from './authorization.service';
import { IFormInfoStorage } from '@clients-nside-io/shared/models';
import { LocalStoreService } from '@clients-nside-io/shared/services';
import { getFullDataForActivatedRoute } from '@clients-nside-io/shared/util';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationFormsGuard implements CanActivate {
  constructor(private authService: AuthorizationService,
              private localStore: LocalStoreService) {}

  // // A function that stores and sets building ID from the route params
  // manageStorage(route: ActivatedRouteSnapshot) {
  //   let buildingId =
  //     route.paramMap.get('buildingId') ?? route.queryParamMap.get('buildingId');
  //   let formName = route.paramMap.get('formName');
  //   const assessCaseUID = route.paramMap.get('assessCaseUID') ?? '';
  //   const path = route.url?.map((urlSegment) => urlSegment.path).join('/');
  //   const currentStorage = JSON.parse(localStorage.getItem('formPath'));
  //   const buildingIdInStorage = localStorage.getItem('buildingId');
  //   const formNameInStorage = localStorage.getItem('formsFormName');
  //
  //   if (!buildingId && !!buildingIdInStorage) {
  //     buildingId = buildingIdInStorage;
  //   }
  //
  //   if (buildingId) {
  //     localStorage.setItem('buildingId', buildingId);
  //     this.authService.setBuildingId(buildingId);
  //   }
  //
  //   if (assessCaseUID) {
  //     localStorage.setItem('formsAssessCaseUID', assessCaseUID);
  //   } else {
  //     localStorage.removeItem('formsAssessCaseUID');
  //   }
  //
  //   if (!formName && !!formNameInStorage) {
  //     formName = formNameInStorage;
  //   }
  //
  //   if (buildingId && formName) {
  //     const formPath: IFormInfoStorage = { buildingId, formName, assessCaseUID };
  //     //this.formService.setFormPath(formPath);
  //     localStorage.setItem('formPath', JSON.stringify(formPath));
  //   }
  //
  //   if (path) {
  //     localStorage.setItem('route', path);
  //   }
  // }

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    //this.manageStorage(route);
    const routeData = getFullDataForActivatedRoute(route);
    this.localStore.setFromRouteData(routeData);

    // const buildingId = localStorage.getItem('buildingId');
    // if (isNaN(Number(buildingId))) {
    //   this.formService.handleError('Invalid Building ID',
    //     'The building ID provided is not a valid number');
    //   return false;
    // }

    if (this.localStore.buildingId) {
      this.authService.setBuildingId(this.localStore.buildingId);
    }

    if (this.authService.isLoggedIn) {
      return of(true);
    }

    return this.authService.checkAuth().pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.authService.authorize();
          // this.authService.loggedIn$.pipe(
          //   map((isNowAuthenticated) => {
          //     return isNowAuthenticated;
          //   })
          // );
          return false;
        }
        if (this.authService.silentRenewEnabled()) {
          this.authService.silentRenewForceSession();
        }
        return true;
      })
    );
  }
}
