import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IApiResponse } from '@clients-nside-io/shared/models';

@Injectable({
  providedIn: 'root'
})
export class ActionHandlerService {
  formSubmissionSuccessSub = new BehaviorSubject<IApiResponse<any> | null>(null);
  formSubmissionErrorSub = new BehaviorSubject<IApiResponse<any> | null>(null);
  formSubmissionSuccess$ = this.formSubmissionSuccessSub.asObservable();
  formSubmissionError$ = this.formSubmissionErrorSub.asObservable();
  formSubmissionSuccessCompletedSub = new BehaviorSubject<boolean>(false);
  formSubmissionSuccessCompleted$ = this.formSubmissionSuccessCompletedSub.asObservable();

  private formSubmissionCompletedSubscription: Subscription = this.formSubmissionSuccessCompleted$.subscribe(completed => {
    if (completed) {
      this.formSubmissionSuccessSub.next(null);
    }
  });
}
