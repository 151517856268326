export interface FilterModel {
  op: 'And' | 'Or' | 'Contains';
  filters: FilterObject[];
}

export interface FilterObject {
  op:
    | 'And'
    | 'Or'
    | 'Contains'
    | 'contains'
    | 'GreaterThanOrEqual'
    | 'LessThanOrEqual'
    | 'lte'
    | 'gte';
  field:
    | 'category'
    | 'buildingId'
    | 'BuildingId'
    | 'received'
    | 'spam'
    | 'notifyItemType'
    | 'status';
  value: Categories[] | string | boolean | number[] | number;
}

export enum Categories {
  Informational = 'Informational',
  Heightened_Awareness = 'Heightened_Awareness',
  Secure_Area = 'Secure_Area',
  Secure_Perimeter = 'Secure_Perimeter',
  Lockdown = 'Lockdown',
  Unknown = 'Unknown',
}
