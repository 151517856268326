import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeUnderscore',
})
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: string, char = ' ', ...args: unknown[]): unknown {
    return value.replace(/_/g, char);
  }
}
