import {
  FilterModel,
  PageLinks,
  PagingInfo,
  TranscriptItem,
  BuildingPoint,
} from './models';

export interface Tip {
  question: string;
  answer: string | number;
}
export interface Tips {
  data: Data;
  _links: Links;
  theTip: Tip;
}
export interface NotifyItemDetail {
  statusCode: number;
  description: string;
  data: Data;
  _links: Links;
  pagingInfo: any;
  tips: Tips[];
}

export interface Links {
  self: Self;
  get_recent_notifications: Self;
}

export interface Self {
  href: string;
  method: string;
}

export interface Data {
  details: TipDetail[];
  buildingId: string;
  category: string;
  categoryConfidence: string;
  subCategory: string;
  recordingUrl: string;
  buildingName: string;
  buildingPoint: BuildingPoint;
  id: string;
  itemSource: string;
  sourceId: string;
  type: string;
  status: string;
  received: string;
}

// interface BuildingPoint {
//   type: string;
//   coordinates: number[];
// }

export interface TipDetail {
  id: string;
  itemId: string;
  type: string;
  json: string;
  updated: string;
}
export enum TipFilterState {
  Opened = 270, //anything above 269
  Closed = 260, //less than or equal 260
  InReview = 261, // 261-269
  All = 'All',
}

interface TipPostModel {
  type: 'NotifyItemDetail';
  subtype: 'ProcessingTipUserAssignment' | 'ProcessingTipNotes';
  data: TipModel;
}
export interface TipModel {
  notes: string;
  status: string;
  notifyItemID: string;
  user: string;
}

export interface TipInteractionModel {
  notes?: string;
  status?: string;
  notifyItemId: number;
  assignedUser?: {
    email: string;
  };
  user?: {
    email: string;
  };
  remove?: boolean;
}

export interface TipItem {
  tips: any[];
  data: TranscriptItem[];
  _links: PageLinks;
  description: string;
  pagingInfo: PagingInfo;
  statusCode: number;
  filterInfo: FilterModel;
}
