import { IAddPropsBase, IAddPropsDefault } from './models';

export enum HandledApiResponseStatus {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

export interface IApiResponseBase extends IAddPropsDefault {
  statusCode: bigint | number;
  description?: string;
  error?: IApiResponseError;
  _links?: IApiResponseLinks;
}

export interface IApiResponseError extends IAddPropsDefault {
  type?: string | null | undefined;
  log?: string | null | undefined;
  exception?: any;
}

export interface IApiLinkInfo extends IAddPropsDefault {
  href: string | null | undefined;
  method?: string | null | undefined;
  body?: any;
  content?: string | null | undefined;
  accept?: string | null | undefined;
}

export type IApiResponseLinks = IAddPropsBase<IApiLinkInfo>

export interface IApiResponse<TData> extends IApiResponseBase {
  data?: TData;
}

export interface INsideUserPartial {
  address: string | undefined;
  city: string | undefined;
  county: string | undefined;
  email: string;
  id: number | string;
  isAdmin: boolean | string | undefined;
  name: string;
  organization: string | undefined;
  phone: string;
  phoneCell: string | undefined;
  position: string;
  state: string | undefined;
  zip: string | undefined;
  displayName: string | undefined;
}
