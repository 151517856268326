export * from './GeneralModels';
export * from './FilterModel';
export * from './NotifyItem';
export * from './user';
export * from './TipsModel';
export * from './ApiModels';
export * from './FormModels';
export * from './IEnvironment';
export * from './DTOs';

// export function sharedModels(): string {
//   return 'shared-models';
// }
