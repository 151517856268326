import { Injectable } from '@angular/core';
import { IAssessDataForRoute, IEnvironment } from '@clients-nside-io/shared/models';
import {
  ActivatedRouteSnapshot,
  Data,
  NavigationEnd,
  Params,
  Router,
  UrlSegment,
  Event
} from '@angular/router';
import { Observable, pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isNumeric } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root',
})
export class DebugUtils {
  private environment: IEnvironment;

  get isProduction() {
    return this.environment?.production === true;
  }

  get isDebugging() {
    return this.environment?.debugging !== false;
  }


  setEnvironment(environment: IEnvironment) {
    this.environment = environment;
  }

  log(message?: any, ...optionalParams: any[]): void {
    if (this.isProduction) {
      return;
    }

    const prefix = this.isDebugging
      ? '***-debug-log-***'
      : '***-staging-log-***'
    console.log(prefix, message, ...optionalParams);
  };

  error(message?: any, ...optionalParams: any[]): void {
    if (this.isProduction) {
      return;
    }

    const prefix = this.isDebugging
      ? '***-debug-log-***'
      : '***-staging-log-***'
    console.error(prefix, message, ...optionalParams);
  };

  info(message?: any, ...optionalParams: any[]): void {
    if (this.isProduction) {
      return;
    }

    const prefix = this.isDebugging
      ? '***-debug-log-***'
      : '***-staging-log-***'
    console.info(prefix, message, ...optionalParams);
  };

  warn(message?: any, ...optionalParams: any[]): void {
    if (this.isProduction) {
      return;
    }

    const prefix = this.isDebugging
      ? '***-debug-log-***'
      : '***-staging-log-***'
    console.warn(prefix, message, ...optionalParams);
  };

  trace(message?: any, ...optionalParams: any[]): void {
    if (!this.isDebugging) {
      return;
    }

    console.info('***-trace-log-***', message, ...optionalParams);
  };
}

export interface IDataForRoute {
  params: Params;
  queryParams: Params;
  data: Data;
  url: UrlSegment[];
  [key: string]: any;
}

export const navigationEndEventFilter = pipe(
  filter<Event>(evt => {
    return (evt instanceof NavigationEnd) == true;
  })
);

export function fullDataForRoute(router: Router): Observable<IDataForRoute> {
  return router.events.pipe(
    navigationEndEventFilter,
    map(event => {
      return getFullDataForRoute(router);
    })
  );
}

function _getFullDataForRoute(route: ActivatedRouteSnapshot): IDataForRoute {
  let rParams: Params = { };
  let qParams: Params = { };
  let rData: Data = { };
  let routeUrl: UrlSegment[] = [];

  (function processRoute(snapshot: ActivatedRouteSnapshot) {
    rParams = { ...rParams, ...snapshot.params };
    qParams = { ...qParams, ...snapshot.queryParams };
    rData = { ...rData, ...snapshot.data };
    routeUrl = [...routeUrl, ...snapshot.url];

    snapshot.children.forEach(processRoute);
  })(route);

  return {
    params: rParams,
    queryParams: qParams,
    data: rData,
    url: routeUrl,
    "urlString": routeUrl?.map(m => m.path).join('/') ?? ''
  } as IDataForRoute;
}

export function getFullDataForRoute(router: Router): IDataForRoute {
  const root = router.routerState.snapshot.root;

  return _getFullDataForRoute(root);
}

export function getFullDataForActivatedRoute(route: ActivatedRouteSnapshot): IDataForRoute {
  return _getFullDataForRoute(route);
}

export function getAssessDataForRoute(routeData: IDataForRoute): IAssessDataForRoute | null {
  if (routeData == null) return null;

  const _tryGet = function(name: string): string {
    return routeData.params?.[name]
      ?? routeData.params?.[name.toLowerCase()]
      ?? routeData.queryParams?.[name]
      ?? routeData.queryParams?.[name.toLowerCase()]
      ?? routeData.data?.[name]
      ?? routeData.data?.[name.toLowerCase()]
      ?? '';
  }

  const buildingId = _tryGet('buildingId');
  const action = _tryGet('action');
  const caseId = _tryGet('assessCaseID')
    ?? _tryGet('assessCaseId')
    ?? _tryGet('assessCaseUID')
    ?? _tryGet('assessCaseUid');
  const submissionId = _tryGet('submissionId')
    ?? _tryGet('submissionID')
    ?? _tryGet('submission-id');
  const formName = _tryGet('formName')
    ?? _tryGet('form-name');
  const isOpeningForm = routeData.data['isOpeningForm'] == true;

  return {
    action: action,
    assessCaseID: caseId,
    formName: formName,
    submissionId: submissionId,
    isOpeningForm: isOpeningForm,
    buildingId: isNumeric(buildingId)
      ? parseInt(buildingId)
      : buildingId
  }
}
