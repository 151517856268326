import { FormlyFieldConfig } from '@ngx-formly/core';
import { IApiResponse } from './ApiModels';
import { IAddPropsDefault, OneOrMore } from './GeneralModels';

export type IFormsApiResponse<TData extends IFormBase | IFormBase[] | OneOrMore<IFormBase>> = IApiResponse<TData>

export interface IFormSubmissionRspBase {
  'form-identifier'?: IFormIdentifier;
  'form-schema'?: IFormSchema;
  'result-count'?: number | undefined;
}

export interface IFormSubmissionMultiResponse extends IFormSubmissionRspBase, IFormsApiResponse<IFormSubmission | IFormSubmission[]> { }

export interface IFormSubmissionResponse extends IFormSubmissionRspBase, IFormsApiResponse<IFormSubmission> { }

export interface IFormSubmissionsResponse extends IFormSubmissionRspBase, IFormsApiResponse<IFormSubmission[]> { }

export type IFormResponse = IFormsApiResponse<IFormSubmission>;

export interface IFormBase {
  formId: IFormIdentifier;
  schema?: IFormSchema;
}

export interface IFormIdentifier {
  uid: string;
  formType: string;
  name: string;
  version: number;
}

export interface IFormQuestionInfo {
  key: string,
  type: string,
  label: string,
  required: boolean,
  hidden: boolean,
  multiple?: boolean
}

export interface IFormSchema {
  fields: string | FormlyFieldConfig[],
  title?: string,
  description?: string,
  model?: any,
  options?: any,
  optionsName?: string,
  questions?: IFormQuestionInfo[]
}

export interface IFormSubject {
  subjectId: string;
}

export type IFormData = IAddPropsDefault;

export interface IFormCurrentModelInfo {
  model?: IFormData;
  step?: string | number;
}

export interface IFormSubmission extends IFormBase {
  subjects?: IFormSubject[] | string[] | null | undefined;
  data?: IFormData;
  submissionId?: string | null | undefined;
  created?: string | null | undefined;
  updated?: string | null | undefined;
  currentStep?: number | string | undefined;
}

export interface IFormInfoStorage {
  buildingId: string;
  formName: string;
  assessCaseId?: string;
  assessCaseUID?: string;
  subjects?: string[] | null | undefined;
}

export const FormTypes = {
  'skills-survey': 'Forms_Anonymous_General',
  'ms-k12-security': 'Forms_Authenticated_General',
  'ms-physical-security': 'Forms_Authenticated_General',
  'assess-student-concern-info': 'Forms_Authenticated_Assess',
  'assess-threat-assessment-screen': 'Forms_Authenticated_Assess',
  'assess-threat-assessment-full': 'Forms_Authenticated_Assess',
  'assess-teacher-staff-interview': 'Forms_Authenticated_Assess',
  'assess-witness-interview': 'Forms_Authenticated_Assess',
  'assess-student-concern-interview': 'Forms_Authenticated_Assess',
  'assess-parent-interview': 'Forms_Authenticated_Assess',
  'assess-suicide-interview': 'Forms_Authenticated_Assess',
  'assess-rms-checklist': 'Forms_Authenticated_Assess',
  'assess-rms-summary': 'Forms_Authenticated_Assess',
  // 'assess-rms-plan': 'Forms_Authenticated_Assess',
  // 'assess-rms-roar': 'Forms_Authenticated_Assess',
  'assess-roar-assignment': 'Forms_Authenticated_Assess',
  'assess-rms-review-schedule': 'Forms_Authenticated_Assess',
  'assess-threat-assessment-summary': 'Forms_Authenticated_Assess',
  'assess-rms-review-log': 'Forms_Authenticated_Assess',
  'assess-roar-activity': 'Forms_Authenticated_Assess',
  // 'assess-interventions-summary': 'Forms_Authenticated_Assess',
  'assess-case-closing-summary': 'Forms_Authenticated_Assess',
  // 'assess-behavioral-assessment-plan': 'Forms_Authenticated_Assess',
  // 'assess-rms-plan-summary': 'Forms_Authenticated_Assess',
  // 'assess-rms-activity': 'Forms_Authenticated_Assess',
  default: 'Forms_Authenticated_General',
  anonymous: 'Forms_Anonymous_General'
};
