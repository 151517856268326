import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizationService } from './authorization.service';
import { LocalStoreService } from '@clients-nside-io/shared/services';
import { getFullDataForActivatedRoute } from '@clients-nside-io/shared/util';
import { of } from 'rxjs/internal/observable/of';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
  constructor(
    private authService: AuthorizationService,
    private localStore: LocalStoreService
  ) {}

  // // A function that stores and sets building ID from the route params
  // getAndSetBuildingId(route: ActivatedRouteSnapshot) {
  //   const buildingId =
  //     route.paramMap.get('buildingId') ?? route.queryParamMap.get('buildingId');
  //
  //   // for nSide|Live, need to save floor Id to localStorage, too
  //   const floorId = route.paramMap.get('floorId') ?? route.queryParamMap.get('floorId');
  //   if (floorId)
  //     localStorage.setItem("floorId", floorId);
  //
  //   const buildingIdInStorage = localStorage.getItem('buildingId');
  //   if (buildingIdInStorage == null && buildingId) {
  //     localStorage.setItem('buildingId', buildingId);
  //     this.authService.setBuildingId(buildingId);
  //   }
  //   if (buildingId && buildingId !== buildingIdInStorage) {
  //     localStorage.setItem('buildingId', buildingId);
  //     this.authService.setBuildingId(buildingId);
  //   }
  //   const path = localStorage.getItem('route');
  //   const activatedRoute = route?.url[0]?.path;
  //   if (activatedRoute) {
  //     localStorage.setItem('route', activatedRoute);
  //   }
  //   // if (!path && activatedRoute) {
  //   //   localStorage.setItem('route', activatedRoute);
  //   // }
  // }

  // FOR NSIDE LIVE, set the current floorId from the App's URL
  setFloorIdForNSLive(route: ActivatedRouteSnapshot) {
    // for nSide|Live, need to save floor Id to localStorage, too
    const floorId = route.paramMap.get('floorId') ?? route.queryParamMap.get('floorId');
    if (floorId)
      localStorage.setItem("floorId", floorId);
  }


  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // this.getAndSetBuildingId(route);
    this.setFloorIdForNSLive(route);
    const routeData = getFullDataForActivatedRoute(route);
    this.localStore.setFromRouteData(routeData);

    if (this.localStore.buildingId) {
      this.authService.setBuildingId(this.localStore.buildingId);
    }

    if (this.authService.isLoggedIn) {
      return of(true);
    }

    return this.authService.checkAuth().pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.authService.authorize();
          this.authService.loggedIn$.pipe(
            map((isNowAuthenticated) => {
              return isNowAuthenticated;
            })
          );
        }
        if (this.authService.silentRenewEnabled()) {
          this.authService.silentRenewForceSession();
        }
        return true;
      })
    );
  }
}
