import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthorizationService } from './authorization.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthorizationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { url } = req;
    const token = this.authService.getToken();
    // console.log('AuthInterceptor - url: ', url);

    if (url.includes('api.nside.io')
      || url.includes('api.dev.nside.io')
      || url.includes('api.staging.nside.io')
      || url.startsWith('https://localhost')) {
      const newReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      return next.handle(newReq);
    } else {
      return next.handle(req);
    }
  }
}
